<template>
  <div class="flex items-stretch gap-3">
    <slot name="input">
      <input-field
        :model-value="modelValue"
        :placeholder="placeholder"
        variant="gray"
        @update:model-value="emit('update:model-value', $event)"
      />
    </slot>
    <dropdown-button class="my-1">
      <template #button>
        <font-awesome-icon
          class="h-5 w-5 py-1"
          icon="ellipsis-vertical"
        />
      </template>
      <template #buttons="{ toggle }">
        <slot name="buttons">
          <slot
            :toggle="toggle"
            name="actions"
          />
          <button
            class="flex items-center gap-1 px-2 py-1 text-sm hover:bg-gray-300"
            @click="
              () => {
                emit('delete')
                toggle()
              }
            "
          >
            <font-awesome-icon
              class="h-4 w-4"
              icon="trash"
            />
            Delete
          </button>
          <button
            v-if="allowMerge"
            class="flex items-center gap-1 px-2 py-1 text-sm hover:bg-gray-300"
            @click="
              () => {
                emit('merge')
                toggle()
              }
            "
          >
            <font-awesome-icon
              class="h-4 w-4"
              icon="code-merge"
            />
            Merge
          </button>
        </slot>
      </template>
    </dropdown-button>
  </div>
</template>

<script setup>
import InputField from '@/components/input/InputField.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { defineOptions } from 'vue'
/**
 * Header for modal forms, with an input field and a dropdown button.
 * @figma https://www.figma.com/file/NycgmenOdWjRDMhseKR9VA/Fankind?type=design&node-id=4018-9665&mode=design&t=iEbMoQl7vAx1llCi-4
 * @usage <FormFieldHeaderInput v-model="myValue" placeholder="My Placeholder" />
 * @example {
 *  "title": "Default",
 *  "description": "The default form field header input.",
 *  "attrs": {
 *   "modelValue": "",
 *   "placeholder": "My Placeholder"
 *  }
 * }
 */


const emit = defineEmits(['update:model-value', 'delete', 'merge'])


















































defineOptions({
  name: 'FormFieldHeaderInput',
})


















































const props = defineProps({
  /**
   * The value of the input field.
   */
  modelValue: {
    type: String,
    default: '',
  },
  /**
   * The placeholder of the input field.
   */
  placeholder: {
    type: String,
    default: '',
  },
  allowMerge: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped></style>
