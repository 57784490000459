<template>
  <div class="flex flex-col">
    <input-wrapper
      ref="inputWrapper"
      :disabled="disabled"
      :empty="isEmpty || _isEmpty"
      :invalid="!!validationError"
      :variant="variant ?? injectedVariant ?? 'default'"
      :wrapper-class="wrapperClass"
    >
      <app-input
        :disabled="disabled"
        :for-id="forId"
        :input-class="inputClass"
        :max="max"
        :min="min"
        :model-value="modelValue"
        :placeholder="placeholder"
        :rows="rows"
        :type="type"
        :variant="variant ?? injectedVariant ?? 'default'"
        :width-by-content="widthByContent && !(isEmpty || _isEmpty)"
        @blur="onBlur"
        @focus="onFocus"
        @update:model-value="emit('update:modelValue', $event)"
      />
      <slot name="icon" />
    </input-wrapper>
    <validation-error
      v-if="innerValidationError || error"
      :error="innerValidationError ?? error"
    />
  </div>
</template>

<script lang="ts" setup>
import InputWrapper from '@/components/ui/InputWrapper.vue'
import AppInput from '@/components/input/AppInput.vue'
import { computed, ComputedRef, inject, ref } from 'vue'
import ValidationError from '@/components/ui/ValidationError.vue'
import { InputProps } from '@/types/props'
import { Rules, validate } from '@/utils/validation'

const emit = defineEmits(['update:modelValue', 'blur', 'focus'])

/**
 * Generic input field component. This component combines the label, input and validation error components.
 * @figma https://www.figma.com/file/NycgmenOdWjRDMhseKR9VA/Fankind?type=design&node-id=3121-13994&mode=design&t=iEbMoQl7vAx1llCi-4
 * @usage <FormFieldInput v-model="myValue" label="My Label" />
 * @usage <FormFieldInput v-model="myValue" label="My Label" variant="gray" />
 * @example {
 *   "title": "Default",
 *   "description": "The default input field layout.",
 *   "attrs": {
 *    "label": "My Custom Label",
 *    "modelValue": "",
 *    "variant": "gray"
 *   }
 * }
 * @example {
 *   "title": "With Error",
 *   "description": "The input field layout with an error.",
 *   "attrs": {
 *    "label": "My Custom Label",
 *    "modelValue": "Wrong input",
 *    "variant": "gray",
 *    "error": "This is an error"
 *   }
 * }
 */
defineOptions({
  name: 'FormFieldInput',
})

const props = withDefaults(
  defineProps<
    InputProps & {
      /**
       * The id of the input field.
       */
      forId?: string | null
      /**
       * The variant of the input field.
       * @values default, outline, minimal, gray
       */
      variant?:
        | 'default'
        | 'outline'
        | 'minimal'
        | 'gray'
        | 'dark-gray'
        | 'minimal-gray'
        | 'searchbar'
        | 'searchbar-white'
      /**
       * The label of the input field.
       */
      label?: string | null
      /**
       * The number of rows of the input field.
       */
      rows?: number | string
      wrapperClass?: string | null
      inputClass?: string | null
      /**
       * The validation rules for the input field.
       * @example { required: true, max: 10 }
       */
      rules?: Rules
      /**
       * Disable validation on blur.
       */
      disableBlurValidation?: boolean
      /**
       * Disable the shake animation on validation error.
       */
      disableShakeAnimation?: boolean
      /**
       * The error message to display.
       */
      error?: string | boolean | null
      isEmpty?: boolean
      widthByContent?: boolean
    }
  >(),
  {
    forId: null,
    variant: undefined,
    label: null,
    type: 'text',
    placeholder: '',
    modelValue: '',
    rows: 1,
    wrapperClass: null,
    inputClass: null,
    rules: () => ({}),
    disableBlurValidation: false,
    disableShakeAnimation: false,
    error: null,
    isEmpty: false,
    min: null,
    max: null,
    widthByContent: false,
    disabled: false,
  }
)

const inputWrapper = ref<InstanceType<typeof InputWrapper> | null>(null)

const injectedError = inject<ComputedRef | null>('input-error', null)
const injectedVariant = inject<string | null>('input-variant', null)
const innerValidationError = ref<string | null>(null)
const validationError = computed(() => {
  return (
    props.error ?? innerValidationError.value ?? injectedError?.value ?? null
  )
})

const _isEmpty = computed(() => {
  return (
    !props.modelValue ||
    (typeof props.modelValue === 'string' && props.modelValue.length === 0) ||
    (typeof props.modelValue === 'number' && props.modelValue === 0)
  )
})

function onBlur(e: FocusEvent) {
  validateInput()
  emit('blur', e)
}

function onFocus(e: FocusEvent) {
  emit('focus', e)
}

function validateInput() {
  if (Object.keys(props.rules).length === 0) return true

  innerValidationError.value = validate(props.modelValue, props.rules)

  if (innerValidationError.value && !props.disableShakeAnimation) {
    // shake animation
    inputWrapper.value?.element?.animate(
      [
        { transform: 'translateX(0)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(10px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(0)' },
      ],
      {
        duration: 500,
        easing: 'ease-in-out',
      }
    )
  }
}
</script>

<style scoped></style>
