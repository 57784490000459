<template>
  <modal-buttons-layout disable-bottom>
    <template #top>
      <merge-selector-modal
        v-model:open="merge"
        :form-field-select-item-component="FormFieldSelectGuest"
        :source-id="form.id"
        :select-attrs="{
          conventionEventId: form.convention_event_id,
        }"
        resource-modal-type="special-guest"
        source-type="guests"
      />
      <form-field-header-input
        allow-merge
        class="w-full"
        @delete="emit('delete')"
        @merge="merge = true"
      >
        <template #input>
          <form-field-image
            v-model="form.avatar"
            all-squared
            class="h-11 w-11"
          />
          <form-field-input
            v-model="form.name"
            :placeholder="capitalize(form.type) + ' Name'"
            class="grow"
            input-class="font-bold"
            variant="gray"
          />
        </template>
      </form-field-header-input>
    </template>
    <div class="flex flex-col gap-4 pt-6">
      <form-input-group-layout :error="form.errors?.type || form.errors?.guest">
        <input-layout
          label="Type"
          layout="grid"
          variant="gray"
        >
          <div class="flex flex-col gap-3">
            <form-field-select-group
              v-model="form.type"
              :options="[
                {
                  label: 'User',
                  value: 'user',
                },
                {
                  label: 'Group',
                  value: 'group',
                },
              ]"
              size="sm"
              variant="primary"
              @update:model-value="form.guest = null"
            />
            <form-field-select-user
              v-if="form.type === 'user'"
              v-model:selected="form.guest"
              allow-create
              single-select
              @create="
                ($event) =>
                  resourceModalHandler.openNew('user', {
                    payload: {
                      first_name: $event,
                    },
                    discardOnClose: false,
                    onSaved: (response) => {
                      form.guest = response.data
                    },
                  })
              "
            >
              <template #selected-user-buttons>
                <button
                  class="ml-2 text-sm"
                  @click="
                    userModalForm.open(
                      axios.get(`/api/partner/users/${form.guest.id}`)
                    )
                  "
                >
                  <font-awesome-icon icon="pen-to-square" />
                </button>
              </template>
            </form-field-select-user>
            <form-field-select-user-group
              v-else-if="form.type === 'group'"
              v-model:selected="form.guest"
              allow-create
              single-select
              @create="
                ($event) =>
                  resourceModalHandler.openNew('user-group', {
                    payload: {
                      name: $event,
                    },
                    discardOnClose: false,
                    onSaved: (response) => {
                      form.guest = response.data
                    },
                  })
              "
            >
              <template #selected-user-buttons>
                <button
                  class="ml-2 text-sm"
                  @click="
                    groupModalForm.open(
                      axios.get(`/api/partner/groups/${form.guest.id}`)
                    )
                  "
                >
                  <font-awesome-icon icon="pen-to-square" />
                </button>
              </template>
            </form-field-select-user-group>
          </div>
        </input-layout>
      </form-input-group-layout>
      <hr/>
      <form-input-group-layout>
        <input-layout
          :error="form.errors?.caption"
          label="Caption"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.caption"
            placeholder="Caption"
            rows="3"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors?.bio"
          label="Bio"
          layout="grid"
          variant="gray"
        >
          <form-field-input-textarea
            v-model="form.bio"
            placeholder="Bio"
            rows="3"
            variant="gray"
          />
        </input-layout>
        <input-layout
          :error="form.errors?.profession_tags"
          label="Profession tags"
          layout="grid"
          variant="gray"
        >
          <form-field-select-tag
            v-model:selected="form.profession_tags"
            labels="inline"
            type="profession"
            variant="gray"
          />
        </input-layout>
      </form-input-group-layout>
    </div>
  </modal-buttons-layout>
</template>

<script setup>
import ModalButtonsLayout from '@/components/modal/layout/ModalButtonsLayout.vue'
import FormFieldHeaderInput from '@/components/input/FormFieldHeaderInput.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import FormInputGroupLayout from '@/components/layout/FormInputGroupLayout.vue'
import FormFieldSelectGroup from '@/components/input/FormFieldSelectGroup.vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInputTextarea from '@/components/input/FormFieldInputTextarea.vue'
import SpecialGuest from '@/models/SpecialGuest'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import { capitalize, ref } from 'vue'
import useModalForm from '@/utils/modalForm'
import User from '@/models/User'
import UserFormView from '@/components/view/UserFormView.vue'
import UserGroup from '@/models/UserGroup'
import UserGroupFormView from '@/components/view/UserGroupFormView.vue'
import FormFieldSelectUser from '@/components/input/FormFieldSelectUser.vue'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormFieldSelectUserGroup from '@/components/input/FormFieldSelectUserGroup.vue'
import FormFieldImage from '@/components/input/FormFieldImage.vue'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import MergeSelectorModal from '@/components/modal/MergeSelectorModal.vue'
import FormFieldSelectGuest from '@/components/input/FormFieldSelectGuest.vue'

const emit = defineEmits(['delete', 'search'])

const props = defineProps({
  form: {
    type: [SpecialGuest, Object],
    required: true,
  },
})

const resourceModalHandler = useResourceModal()

const merge = ref(false)

const userModalForm = useModalForm(
  { ...new User() },
  {
    component: () => UserFormView,
    onClose: () => {
      return axios.post(
        `/api/partner/users/${userModalForm.form.id}?_method=PUT`,
        userModalForm.form.formData()
      )
    },
    onDelete: () => {
      return axios.delete(`/api/partner/users/${userModalForm.form.id}`)
    },
  }
)

const groupModalForm = useModalForm(
  { ...new UserGroup() },
  {
    component: () => UserGroupFormView,
    onClose: () => {
      return axios.post(
        `/api/partner/groups/${groupModalForm.form.id}?_method=PUT`,
        groupModalForm.form.formData()
      )
    },
    onDelete: () => {
      return axios.delete(`/api/partner/groups/${groupModalForm.form.id}`)
    },
  }
)
</script>

<style scoped></style>
