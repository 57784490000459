<template>
  <div
    ref="element"
    :class="[
      wrapperClass,
      miniMode ? 'absolute h-full w-0 p-0 ring-0' : inputClass,
    ]"
    class="group relative flex select-none flex-row"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { InputVariant } from '@/types/designVariants'

const props = withDefaults(
  defineProps<{
    wrapperClass?: string
    empty?: boolean
    variant?: InputVariant
    invalid?: boolean
    disabled?: boolean
    miniMode?: boolean
  }>(),
  {
    wrapperClass: '',
    empty: false,
    variant: 'default',
    invalid: false,
    disabled: false,
    miniMode: false,
  }
)

const element = ref<HTMLElement | null>(null)
const inputClass = computed(() => {
  switch (props.variant) {
    case 'default':
      return [
        'overflow-visible border-b-1.5 border-gray-300 focus-within:border-gray-600',
      ]
    case 'gray':
      return [
        'items-center rounded px-3 py-2 text-slate-700 ring-gray-500 ring-opacity-50 duration-100 focus-within:ring-1',
        {
          'bg-gray-100': props.empty,
          'bg-gray-100 opacity-50': props.disabled,
          'bg-transparent group-focus-within:bg-gray-100':
            !props.empty && !props.invalid,
          'bg-[#fce1e5] outline outline-red-400': props.invalid,
        },
      ]
    case 'dark-gray':
      return [
        'items-center rounded px-3 py-2 ring-gray-800 ring-opacity-50 duration-100 focus-within:ring-1',
        {
          'bg-gray-300': props.empty,
          'bg-gray-200': !props.empty,
        },
      ]
    case 'outline':
      return [
        'items-center rounded border border-gray-300 bg-transparent px-3 py-2',
      ]
    case 'minimal':
      return [
        'items-center rounded bg-transparent py-1 text-sm font-normal text-gray-800',
      ]
    case 'minimal-gray':
      return [
        'items-center rounded px-1 py-1.5 text-sm text-gray-600 ring-gray-500 ring-opacity-50 duration-100 focus-within:ring-1',
        {
          'bg-gray-100': props.empty,
        },
      ]
    case 'searchbar':
      return ['items-center rounded-full bg-gray-200 px-3 py-1 text-paragraph']
    case 'searchbar-white':
      return [
        'p-2 bg-white border border-slate-300 rounded-lg text-sm flex-row-reverse gap-2 items-center',
      ]
    case 'searchbar-gray':
      return [
        'p-2 bg-[#F2F5F9] rounded-lg text-sm flex-row-reverse gap-2 items-center',
      ]
    default:
      console.warn('Unknown variant', props.variant)
      return []
  }
})
defineExpose({ element })
</script>
